import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      height: 'min-content',
      border: 'none',
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.09)',

      '&:hover': {
        boxShadow: '0 0 10px 3px rgb(0 18 52 / 10%)',
      },
    },
    avatar: {
      width: '85px',
      height: '85px',
      margin: spacing(5, 'auto', 2),
    },
    cardItem: {
      flex: '1 1 auto',
      opacity: 1,
      overflow: 'hidden',
      padding: spacing(6, 5),
      position: 'relative',
    },
    name: {
      marginTop: spacing(2.5),
      color: colors.black.darker,
      fontSize: 16,
      width: '100%',
      textAlign: 'center',
    },
    organisation: {
      marginTop: spacing(2.5),
      color: colors.grey.main,
      fontWeight: 500,
      fontSize: 16,
      width: '100%',
      textAlign: 'center',
    },
    cardBody: {
      marginTop: spacing(2.5),
      color: colors.grey.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      fontSize: 14,
      textAlign: 'center',
    },
    emailLink: {
      display: 'inline-grid',
      color: colors.blue.main,
    },
    footer: {
      borderTop: `1px solid ${colors.grey.lightest}`,
      height: 48,
    },
    footerBtn: {
      flex: '1 1 33%',
      color: colors.black.darker,
      border: 'none',
      borderRadius: 0,

      '&>span': {
        fontWeight: 500,
        fontSize: '13px',
      },

      '&:nth-child(2)': {
        borderLeft: `1px solid ${colors.grey.lightest}`,
        borderRight: `1px solid ${colors.grey.lightest}`,
      },

      '&:hover': {
        color: colors.white.lightest,
        backgroundColor: colors.blue.dark,
      },
    },
    linkButton: {
      flex: '1 1 33%',
      color: colors.grey.darker,

      '&:nth-child(2)': {
        borderLeft: `1px solid ${colors.grey.lightest}`,
        borderRight: `1px solid ${colors.grey.lightest}`,
        borderRadius: 0,
      },

      '&:hover': {
        color: colors.blue.main,
      },
    },
    [breakpoints.down('xl')]: {
      root: {
        width: 'calc(20% - 14px)',
      },
    },
    [breakpoints.down('lg')]: {
      root: {
        width: 'calc(25% - 12px)',
      },
    },
    [breakpoints.down('md')]: {
      root: {
        width: 'calc(33% - 10px)',
      },
    },
    [breakpoints.down('sm')]: {
      root: {
        width: 'calc(50% - 8px)',
      },
    },
    [breakpoints.down('xs')]: {
      root: {
        width: '100%',
      },
    },
  });
